import React from "react"
import { classNames } from "../../helpers"
import { Container } from "../ui"

const CardsGrid = ({ items, title, subtitle, titleClass }) => {
  return (
    <div className="relative pt-20">
      <Container>
        <div className="relative">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              {title}
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              {subtitle}
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {items.map((item) => (
              <div
                key={item.title}
                className="flex flex-col rounded-lg shadow-md ring-1 ring-gray-100 overflow-hidden text-center px-6"
              >
                {!!item.icon && (
                  <div className="flex-shrink-0 pt-6">
                    <div className="text-primary ring-2 ring-[#ffebe3] bg-[#ffebe3] rounded-full w-12 h-12 inline-flex items-center justify-center">
                      {item.icon}
                    </div>
                  </div>
                )}
                <div className="flex-1 bg-white pt-6 pb-6 flex flex-col">
                  <p className={classNames("text-xl font-semibold text-gray-900", titleClass)}>
                    {item.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CardsGrid
