import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/roro3.jpg"
import IMG2 from "./../../assets/images/support1.jpeg"
import IMG3 from "./../../assets/images/terminal1.jpg"

export default function CompanyPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        title="About Icon Logistics Services"
        className="bg-primary-50 mt-0 pt-8"
        hideBackPattern
      >
        In a fast-changing shipping world, Icon Logistics Services has set
        itself apart as a leading freight forwarder with its fresh and unique
        approach ever since its foundation back in 2009.
        <br />
        <br />
        With a visionary and digital mindset, we provide distinctive advantages
        to our clients worldwide. We have worked hard to create a smart and
        fruitful network that ensures stability and reliable services. Not only
        that but also we have made it our mission to focus on the needs of our
        customers which stand at the heart of all our decisions.
      </CenteredTextBlock>
      <LeftImageText
        title="What Makes Icon Logistics Services Unique"
        image={IMG1}
      >
        At Icon Logistics Services, we believe there is a better way to do
        freight forwarding. A more valuable way where clients are earned rather
        than bought. We're obsessively passionate about providing digital
        freight and logistics services, and our mission is to help clients
        experience the joy of a smoothless shipping experience.
        <br />
        <br />
        ​We make the most of our flexibility and creativity to provide superior
        services and solutions adapted to our customers’ evolving needs. Our
        professionalism is built on years of experience, skills, and reputation
        as forward thinking innovators in the shipping business.
        <br />
        <br />
        With a proud, decades-long heritage, we are confident in our ability to
        maintain our position as a top industry performer dedicated to
        efficiency and profitability, as we continue to deliver exceptional
        service to our customers.
      </LeftImageText>
      <RightImageText title="We Are All Ears" image={IMG2} variant="light">
        We attentively listen to our clients in order to empower them and
        provide superior customer service.
        <br />
        <br />
        We do that by focusing on:
        <br />
        <ul className="list-disc list-inside">
          <li>Gathering and understanding requirements </li>
          <li>Customize and provide the most suitable solution and service</li>
          <li>Execution while keeping the client in the loop</li>
        </ul>
        <br />
        Apart from that, our team offers constant guidance and advisory through
        the whole shipping process. This is to ensure that our clients receive
        what they deserve which is the best services as per their needs without
        any uncertainties or ambiguities.
        <br />
        <br />
        We also provide strategic advisory and logistics services to commercial
        companies including a variety of Fortune 500 companies local, state,
        national governments and international bodies, as well as universities,
        hospitals and utilities.
      </RightImageText>
      <LeftImageText
        title="How We Got Here"
        image={IMG3}
        link={{
          label: "Read More about Icon Logistics Services Culture",
          to: "/culture",
        }}
      >
        “We’re out to build better freight services and solutions,” was our
        motto when we first founded Icon logistics Services back in 2009, and
        still is.
        <br />
        <br />
        It all started with one problem followed by dozens that changed
        everything. We noticed that the logistics and freight industry has a lot
        of gaps when it comes to offering innovative solutions and services,
        especially digital ones. From time-consuming manual processes to the
        lack of coordination and planning between involved parties in the
        shipping parties. Kind of a double whammy, when you think about it. So,
        we did away with the traditional way of doing freight forwarding.
      </LeftImageText>
      
      <PrimaryCTA
        title="Ready to Help!"
        subtitle="Have you been frustrated and disappointed with your logistics and shipping needs lately? Contact Icon Logistics Services and we will help you achieve your goals."
		button={{label: "Contact Us", to: "/contact"}}
      />
    </>
  )
}
