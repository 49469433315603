import {
  BadgeCheckIcon,
  CogIcon,
  GlobeIcon,
  HandIcon,
  HeartIcon,
  LocationMarkerIcon,
  SupportIcon,
} from "@heroicons/react/solid"
import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import NewsGrid from "../../components/news/NewsGrid"

export default function NewsPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        className="bg-primary-50 mt-0 pt-0 py-0"
        hideBackPattern
      >
      </CenteredTextBlock>
	  <NewsGrid />
    </>
  )
}
