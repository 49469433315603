import React, { useState } from "react"

import { ClockIcon, MailIcon, PhoneIcon } from "@heroicons/react/outline"
import Select from "./elements/Select"

export default function QuoteForm() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    let myForm = e.currentTarget
    let formData = new FormData(myForm)
    fetch("https://iconshipping.wpengine.com/forms/quote.php", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("Form successfully submitted")
          setSuccess(true)
          setError(false)
        } else {
          setSuccess(false)
          setError(true)
        }
      })
      .catch((error) => {
        setError(true)
        setSuccess(false)
      })
  }

  return (
    <div className="">
      <div className="relative">
        <div className="grid grid-cols-1">
          {/* Contact form */}
          <div className="lg:col-span-2">
          

            <iframe
              style={{
                width: "100%",
                height: "850px",
                minHeight: "55vh",
                border: "none",
                overflowY: "auto",
              }}
              src="https://portal.iconshipping.com/quotations/request?isFramed=true&brandColor=rgb(254,91,24)"
              frameborder="0"
            ></iframe>
            {/* <form
              name="Quotation From"
              method="POST"
              className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              onSubmit={handleSubmit}
            >
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Contact Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="position"
                  className="block text-sm font-medium text-gray-900"
                >
                  Position/Title
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="position"
                    id="position"
                    autoComplete="position"
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-900"
                >
                  Company
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="company"
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-900"
                >
                  Country
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="country"
                    id="country"
                    autoComplete="country"
                    required
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Phone
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                    aria-describedby="phone-optional"
                  />
                </div>
              </div>

              <Select options={pols} name="pol" required />
              <Select options={pods} name="pod" required />

              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Cargo Description
                  </label>
                  <span id="message-max" className="text-sm text-gray-500">
                    Max. 500 characters
                  </span>
                </div>
                <div className="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    rows={4}
                    required
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border border-gray-300 rounded-md"
                    aria-describedby="message-max"
                    defaultValue={""}
                  />
                </div>
              </div>

              <div className="">
                <label
                  htmlFor="unit"
                  className="block text-sm font-medium text-gray-900"
                >
                  Number of units/cbm
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="unit"
                    id="unit"
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="">
                <label
                  htmlFor="period"
                  className="block text-sm font-medium text-gray-900"
                >
                  Desired period of shipment
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="period"
                    id="period"
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2 sm:flex sm:justify-end">
                <button
                  type="submit"
                  className="mt-2 w-full inline-flex items-center justify-center px-6 py-2 border border-transparent rounded-xl shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto"
                >
                  Submit
                </button>
              </div>

              {error && (
                <div className="bg-red-100 col-span-2 px-3 rounded-xl py-3 items-center justify-center flex">
                  <div className="text-red-500 font-medium">Sorry! But we couldn't process your request.</div>
                </div>
              )}
              {success && (
                <div className="bg-green-100 col-span-2 px-3 rounded-xl py-3 items-center justify-center flex">
                  <div className="text-green-500 font-medium">Thank you! Your request has been submitted.</div>
                </div>
              )}
            </form> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const pols = [
  "Port of loading",
  "Amsterdam",
  "Antwerp",
  "Baltimore",
  "Bayonne NJ",
  "Boston",
  "Hamburg",
  "Jacksonville",
  "Malaga",
  "Santo Domingo",
  "Savona",
  "Sheerness",
  "Wilmington",
  "Abidjan",
  "Conakry",
  "Cotonou",
  "Dakar",
  "Douala",
  "Freetown",
  "Lagos",
  "Libreville",
  "Lome",
  "Luanda",
  "Misrata",
  "Nouakchott",
  "Pointe Noire",
  "Tema",
  "Walvis Bay",
]

const pods = [
  "Port of discharging",
  "Abidjan",
  "Conakry",
  "Cotonou",
  "Dakar",
  "Douala",
  "Freetown",
  "Lagos",
  "Libreville",
  "Lome",
  "Luanda",
  "Misrata",
  "Nouakchott",
  "Pointe Noire",
  "Tema",
  "Walvis Bay",
  "Amsterdam",
  "Antwerp",
  "Baltimore",
  "Bayonne NJ",
  "Boston",
  "Hamburg",
  "Jacksonville",
  "Malaga",
  "Santo Domingo",
  "Savona",
  "Sheerness",
  "Wilmington",
]
