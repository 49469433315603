import * as React from "react"

function GooglePlayIcon({ width, height, className, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.52 57.37"
      className={className}
    >
      <defs>
        <style>{".cls-1{fill:#383838}"}</style>
      </defs>
      <title>{"Asset 1"}</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="light">
          <path
            className="cls-1"
            d="M1 1a4.52 4.52 0 0 0-1 3v49.36a4.91 4.91 0 0 0 .71 2.71l27.38-27.7ZM29.07 27.39l8.45-8.55L5.67.74A4.28 4.28 0 0 0 2.16.17ZM29.07 29.36 1.73 57a4 4 0 0 0 3.94-.38l32.24-18.31ZM49.18 25.46l-10.41-5.91L30 28.37l9.12 9.23 10-5.69c3.18-1.77 3.18-4.67.06-6.45Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default GooglePlayIcon
