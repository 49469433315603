import React from "react"
import { classNames } from "../../helpers"
import { Button } from "../ui"
import IMG1 from "./../../assets/images/modes1.jpeg"

export default function CenteredTextBlock({title, children, button, ctaLink, className, hideBackPattern}) {
  return (
    <div className={classNames("relative py-20", className)}>
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-1 lg:gap-24 lg:items-center">
        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          {/* Content area */}
          <div className=" relative z-10 text-center">
            <h2 className="text-3xl text-primary font-extrabold tracking-tight sm:text-4xl leading-[1.5]">
              {title}
            </h2>
            <div className="mt-6 text-gray-800 space-y-6">
              <p className="text-lg">
               {children}
              </p>
            </div>
            {!!button && <div className="mt-8">
              <Button to={button.to}>{button.label}</Button>
            </div>}
            {!!ctaLink && <div className="mt-8">
              <a href={ctaLink.to} className="text-base font-medium text-primary-500">
			  	{ctaLink.label} &nbsp; &rarr;
              </a>
            </div>}

			
			
          </div>
		  {!hideBackPattern && <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-left-8 lg:top-12 z-0"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={392}
                fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
              />
            </svg>}
        </div>
      </div>
	  
    </div>
  )
}
