import {
	BadgeCheckIcon,
	CogIcon,
	GlobeIcon,
	HandIcon,
	HeartIcon,
	SupportIcon,
  } from "@heroicons/react/solid"
  import React from "react"
  import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
  
  export default function CareersPage({ data }) {
	return (
	  <>
		<CenteredTextBlock
		  title="Our Hiring Philosophy "
		  className="bg-primary-50 mt-0 pt-8"
		  hideBackPattern
		>
		 To be the go-to freight forwarder company, we need a workforce that reflects the diversity of our communities and clients. We want Icon Logistics Services to be a place where everyone feels welcomed and engaged. 
		</CenteredTextBlock>
  
		</>
	)
  }
  
  const principles = [
	{
	  title: "Integrity",
	  content:
		"We never waver on honesty and trustworthiness nor on our support and dedication to our employees, clients and communities.",
	  icon: <BadgeCheckIcon className="w-6 h-6" />,
	},
	{
	  title: "Personalized Service",
	  content:
		"No two clients are served in the exact manner. Each client is served by what suits his/her short-term and long-term business goals.",
	  icon: <CogIcon className="w-6 h-6" />,
	},
	{
	  title: "Empowerment",
	  content:
		"We help our employees step up the ladder of growth and success. We want them to be the best version of themselves, and provide  ongoing training.",
	  icon: <HandIcon className="w-6 h-6" />,
	},
	{
	  title: "Exceptional Customer Service",
	  content:
		"Our clients have open access to all levels of our employees whenever required; including even after hours. We believe it is the key to build long-lasting and fruitful relationships with our clients.",
	  icon: <SupportIcon className="w-6 h-6" />,
	},
	{
	  title: "Respect",
	  content:
		"We will treat our employees and clients the way we wish to be treated: with respect for their opinions and feelings. We strive to understand their perspectives to foster a healthy and respectful environment.",
	  icon: <HeartIcon className="w-6 h-6" />,
	},
	{
	  title: "Social Responsibility",
	  content:
		"We continuously try to give back to society by supporting  humanitarian, environmental and socially responsible initiatives. Case in point, we offer scholarships and sponsor local programs about development in Africa.",
	  icon: <GlobeIcon className="w-6 h-6" />,
	},
  ]
  