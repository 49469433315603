import React from "react"
import CardsGrid from "../components/elements/CardsGrid"
import CenteredTextBlock from "../components/elements/CenteredTextBlock"
import PrimaryCTA from "../components/elements/PrimaryCTA"
import RightImageText from "../components/elements/RightImageText"
import IMG1 from "./../assets/images/roro5.jpg"
import IMG2 from "./../assets/images/container1.jpg"


export default function ShipmentsPage({ data }) {
  return (
    <>
      <RightImageText
        title="RoRo Shipping"
        image={IMG1}
        button={{
          label: "Get a Quotation",
          to: "/request-a-quote",
        }}
        variant="light"
        className="!mt-0"
      >
        With over 10 years of experience, we have established a reputation for
        transporting diverse RoRo machinery. But we wouldn’t have accomplished
        that weren’t it for the trust of our clients.
        <br />
        <br />
        Every single day, our professional team commit themselves to provide
        exceptional customer support by utilizing their thorough knowledge in
        customs and documentation requirements along with the implications of
        duties and taxes during export and import.
      </RightImageText>

      <CenteredTextBlock
        title="What is RoRo Shipping?"
        className="bg-white pb-0"
        hideBackPattern
      >
        It is an incredibly popular method of shipping cars, boats, light
        aircrafts and other items that do not fit inside a standard container.
        Instead of the freight being loaded into a container, it is either
        driven on and off the vessel under its own power, or loaded onto a
        specialty pallet to be moved via a tractor to the ships’ hold.
        <br />
        <br />
        We ship to most African countries and work with leading shippers
        worldwide.
      </CenteredTextBlock>

      <CardsGrid
        items={roroUsers}
        titleClass="text-primary"
        title="Industries that Rely on Shipping"
      />

      <RightImageText
        title="Container Shipping"
        image={IMG2}
        button={{
          label: "Get a Quotation",
          to: "/request-a-quote",
        }}
        variant="light"
      >
        At Icon Logistics Services, we ensure that the containers meet all ISO
        requirements, and the latest safety regulations. How? A worldwide
        inspection procedure is conducted on a regular basis. It is always
        supervised, and the equipment’s quality is confirmed by a certification
        society.
      </RightImageText>

      <CenteredTextBlock
        title="Loading Containers for Ocean Freight"
        className="bg-white"
        hideBackPattern
      >
        We provide full container load (FCL) or less than container load (LCL)
        for delivering your shipments to the right place and on time.
        <br />
        <br />
        Loading your shipments in a full container load means that the entire
        container is at your disposal as long as they do not exceed the maximum
        weight allowed. However, shipping in LCL, the container will be shared
        with other shippers.
      </CenteredTextBlock>

     

      <PrimaryCTA
        title="Ready to Get Started?"
        subtitle="One of our experts would love to get in touch with you to give you a thorough understanding of our services and solutions"
        button={{ label: "Get Started", to: "/contact" }}
      />
    </>
  )
}

const roroUsers = [
  {
    title: "Airports",
    content: "Lifts - Loaders - Tugs",
  },
  {
    title: "Agricultural Equipment",
    content: "Bailers - Combines - Pickers",
  },
  {
    title: "Automotive",
    content: "Ambulances - Buses - Cars - Trucks - Tractors - Trailers",
  },
  {
    title: "Construction Equipment",
    content: "Cranes -  Backhoes - Dozers - Loaders",
  },
  {
    title: "Oil, Gas & Energy Equipment",
    content: "Drill Rigs - Pumps",
  },
  {
    title: "Road Building Equipment",
    content: "Excavation And Extraction",
  },
]
