import React from "react"
import { classNames } from "../../helpers"
import { Button } from "../ui"

export default function RightImageText({
  title,
  children,
  button,
  className,
  image,
  link,
  variant = "default",
}) {
  const variants = {
    default: {
      root: "bg-white ",
      title: "text-black ",
      content: "text-gray-500 ",
      pattern: "text-gray-200"
    },
    primary: {
      root: "bg-primary ",
      title: "text-white ",
      content: "text-white text-opacity-70 ",
      pattern: "text-gray-200"
    },
    light: {
      root: "bg-primary-50 ",
      title: "text-primary ",
      content: "text-gray-900 text-opacity-70 ",
      pattern: "text-primary-200"
    }
  }
  return (
    <div className={classNames("relative mt-20", variants[variant].root, className)}>
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          {/* Content area */}
          <div className="py-12 sm:py-16 lg:py-20">
            <h2 className={classNames("text-3xl font-extrabold tracking-tight sm:text-4xl leading-[1.5]", variants[variant].title)}>
              {title}
            </h2>
            <div className={classNames("mt-6 space-y-6", variants[variant].content)}>
              <p className="text-lg">{children}</p>
            </div>
            {!!button && (
              <div className="mt-8">
                <Button to={button.to}>{button.label}</Button>
              </div>
            )}
            {!!link && (
              <div className="mt-8">
                <a
                  href={link.to}
                  className="text-base font-medium text-primary-500"
                >
                  {link.label} &nbsp; &rarr;
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="relative sm:py-16 lg:py-0 h-full">
          {variant != "light" && (
            <div
            aria-hidden="true"
            // className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
          >
            <svg
              className="hidden md:visible absolute top-8 left-1/2 -ml-3 lg:-left-8 lg:right-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className={classNames(variants[variant].pattern)}
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={392}
                fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
              />
            </svg>
          </div>
          )}
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20 h-full">
            {/* Testimonial card*/}
            <div className="relative h-full rounded-2xl shadow-xl overflow-hidden">
              <img
                className=" inset-0 h-full w-full object-cover "
                src={image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
