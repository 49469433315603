import React from "react"
import ContactSection from "../components/ContactSection"
import { Container } from "../components/ui"

export default function ContactPage({ data }) {
  return (
    <div className="bg-primary-50 py-20">
      <Container>
        <ContactSection />
      </Container>
    </div>
  )
}
