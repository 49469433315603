import React from "react"
import { classNames } from "../../helpers"
import { Button, Container } from "../ui"
import ChildrenNavigation from "./ChildrenNavigation"

export default function PageHeader({
  title,
  subtitle,
  image,
  navigation,
  parent,
  slug,
  bottomColor = "bg-primary-50",
}) {
  if (navigation) {
    // sort navigation items alphabetically by title
    navigation.sort((a, b) => {
      if (a.menuOrder < b.menuOrder) return -1
      if (a.menuOrder > b.menuOrder) return 1
      return 0
    })
  }

  return (
    <div className="">
      <Container>
        <div className="py-10 md:py-20 text-center">
          <h1 className="text-5xl text-black font-semibold tracking-tight leading-[1.2]">
            {title}
          </h1>
          <p className="text-lg text-gray-500 mt-6">{subtitle}</p>
          {!!image && (
            <div className="mt-8">
              <Button to="/request-a-quote" size="wide">
                Get Started
              </Button>
            </div>
          )}
        </div>
        {!!image && (
          <div
            className={classNames(
              "relative h-80  -mb-40  rounded-2xl shadow-xl overflow-hidden",
              bottomColor
            )}
          >
            <img className="h-full w-full object-cover " src={image} alt="" />
            {!!navigation[0] && (
              <ChildrenNavigation
                slug={slug}
                data={[
                  { title: "Overview", slug: parent?.slug || slug },
                  ...navigation,
                ]}
              />
            )}
          </div>
        )}
      </Container>
      {!!image && <div className="bg-primary-50 h-[13rem]"></div>}
    </div>
  )
}
