import React from "react"

export default function PrimaryCTA({title, subtitle, button}) {
  return (
    <div className="bg-primary">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">{title}</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-primary-200">{subtitle}</p>
        {!!button && (
          <a
            href={button.to}
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-xl text-primary-600 bg-white hover:bg-primary-50 sm:w-auto"
          >
            {button.label}
          </a>
        )}
      </div>
    </div>
  )
}
