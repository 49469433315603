import React from "react"

import delta from "./assets/images/logos/delta.svg"
import united from "./assets/images/logos/united.svg"
import msc from "./assets/images/logos/msc.svg"
import sallaumlines from "./assets/images/logos/sallaumlines.svg"
import acl from "./assets/images/logos/acl.svg"

export default function CarriersLogos() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5 items-center">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={delta} alt="Tuple" />
          </div>

          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-16" src={msc} alt="StaticKit" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img className="h-16" src={acl} alt="Transistor" />
          </div>
          <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
            <img className="w-28" src={sallaumlines} alt="Workcation" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={united} alt="Mirage" />
          </div>
        </div>
      </div>
    </div>
  )
}
