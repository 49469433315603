import React from "react"
import PrimaryCTA from "../components/elements/PrimaryCTA"
import { Container } from "../components/ui"

export default function DestinationsPage({ data }) {
  return (
    <>
      <div className="py-20 bg-primary-50">
        <Container>
          <div className="items-center justify-center grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {destinations.map((item) => (
              <div className="py-4 px-4 flex  items-center bg-white shadow-sm rounded-xl">
                <div className="relative rounded-full overflow-hidden h-12 w-12 mr-4">
                  <img
                    className="h-full w-full object-cover "
                    src={`https://countryflagsapi.com/svg/${item.code}`}
                    alt=""
                  />
                </div>
                <h3>{item.name}</h3>
              </div>
            ))}
          </div>
        </Container>
      </div>

      <PrimaryCTA
        title="Found your next shipment’s destination?"
        subtitle="Reach out to discuss your shipping requirements."
        button={{ label: "Get in touch", to: "/contact" }}
      />
    </>
  )
}

const destinations = [
  {
    name: "Lagos",
    code: "NG",
  },
  {
    name: "Lome",
    code: "TG",
  },
  {
    name: "Cotonou",
    code: "BJ",
  },
  {
    name: "Tema",
    code: "UG",
  },
  {
    name: "Dakar",
    code: "SN",
  },
  {
    name: "Abidjan",
    code: "CI",
  },
  {
    name: "Pointe Noire",
    code: "CG",
  },
  {
    name: "Luanda",
    code: "AO",
  },
  {
    name: "Douala",
    code: "CM",
  },
  {
    name: "Libreville",
    code: "GA",
  },
  {
    name: "Takoradi",
    code: "GH",
  },
  {
    name: "Banjul",
    code: "GM",
  },
  {
    name: "Conakry",
    code: "GN",
  },
  {
    name: "Malabo",
    code: "GQ",
  },
  {
    name: "Freetown",
    code: "SL",
  },
]
