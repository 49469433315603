import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import DefaultPage from "../containers/DefaultPage"
import CompanyPage from "../containers/company"
import PageHeader from "../components/elements/PageHeader"
import CulturePage from "../containers/company/culture"
import CareersPage from "../containers/company/careers"
import NewsPage from "../containers/company/news"
import ServicesPage from "../containers/services"
import ByServicesPage from "../containers/services/by-services"
import ByTransportModesPage from "../containers/services/by-transport-modes"
import ByInnovationPage from "../containers/services/by-innovation-and-digitization"
import ShipmentsPage from "../containers/shipments"
import DestinationsPage from "../containers/destinations"
import ContactPage from "../containers/contact"
import QuotePage from "../containers/quote"

export default function Page(props) {
  const { page, allWpPage } = props.data

  let children = allWpPage?.nodes[0]?.wpChildren?.nodes || [];
  let siblings = allWpPage?.nodes[0]?.wpParent?.node?.wpChildren.nodes || [];

  if(siblings.length > 0) page.children = siblings;
  if(children.length > 0) page.children = children;

  page.parent = allWpPage?.nodes[0]?.wpParent?.node;

  console.log(page.children)

  const render = (slug) => {
    switch (slug) {
      case "company":
        return <CompanyPage data={page} />
      case "culture":
        return <CulturePage data={page} />
      case "careers":
        return <CareersPage data={page} />
      case "news":
        return <NewsPage data={page} />
      case "services":
        return <ServicesPage data={page} />
      case "by-services":
        return <ByServicesPage data={page} />
      case "by-transport-modes":
        return <ByTransportModesPage data={page} />
      case "by-innovation-and-digitization":
        return <ByInnovationPage data={page} />
      case "shipments":
        return <ShipmentsPage data={page} />
      case "destinations":
        return <DestinationsPage data={page} />
      case "contact":
        return <ContactPage data={page} />
      case "request-a-quote":
        return <QuotePage data={page} />
      default:
        return <DefaultPage data={page} />
    }
  }

  return (
    <Layout {...page}>
      <PageHeader
        title={page?.title}
        subtitle={page?.subtitle}
        image={page?.image?.url}
        navigation={page?.children}
        parent={page?.parent}
        slug={page?.slug}
      />
      {render(page?.slug)}
    </Layout>
  )
}

export const query = graphql`
  query PageContent($id: String!, $slug: String!) {
    page(id: { eq: $id }) {
      id
      title
      slug
      description
      subtitle
      image {
        id
        url
      }
      html
      children {
        id
      }
    }
    allWpPage(filter: {slug: {eq: $slug}}) {
      nodes {
        id
        title
        slug
        wpParent {
          node {
            slug
            ... on WpPage {
              wpChildren {
                nodes {
                  slug
                  ... on WpPage {
                    title
                    menuOrder
                  }
                }
              }
            }
          }
        }
        wpChildren {
          nodes {
            id
            slug
            ... on WpPage {
              id
              title
              menuOrder
            }
          }
        }
      }
    }
  }
`
