import React from "react"
import AppleIcon from "../elements/AppleIcon"
import GooglePlayIcon from "../elements/GooglePlayIcon"
import { Container } from "../ui"
import MobileImage from "./../../assets/images/icon-app-intro.png"

export default function MobileAppCTA({ detailed }) {
  return (
    <div className="bg-primary  overflow-hidden relative">
      <Container>
        <div className="flex flex-col-reverse md:flex-row">
          <div className="pt-20 w-full md:w-2/5 h-[400px] md:h-full">
            <img src={MobileImage} className="w-[300px] relative md:absolute" />
          </div>
          <div className="pt-20 pb-4 md:pb-40 md:pt-40 w-full md:w-3/5 max-w-lg">
            <h2 className="text-white text-3xl font-extrabold tracking-tight sm:text-4xl leading-[1.5]">
              {detailed
                ? "Mobile App: Arming Clients With The Features They Need "
                : "Leveraging the Power of Technology for Going Digital"}
            </h2>
            <div className="mt-6 text-white text-opacity-70 space-y-6">
              <p class="text-lg">
                {detailed
                  ? "Icon Logistics Services mobile application is a super easy, convenient and intuitive way to manage your shipments. A tap on your smartphone instantly directs you to a wide range of cargo shipping services, essential information and a bunch of helpful features:"
                  : "Round-the-clock services for scheduling, booking, tracking, financials and more. All are available anywhere within a tap."}

                {detailed && (
                  <ol className="list-decimal mt-4  list-inside">
                    <li>Browse sailing schedules </li>
                    <li>Track your shipments</li>
                    <li>
                      Manage and Create instant bookings
                    </li>
                    <li>View your stock and manifest </li>
                    <li>Downlodable dock receipts, Draft B/Ls, and other docuemnts</li>
					<li>Transparent access to your invoices</li>
                  </ol>
                )}
              </p>
            </div>
            <div className="mt-12">
              <a
                href="https://play.google.com/store/apps/details?id=com.logisoft.iconshipping"
                target="_blank"
                className="text-black flex md:inline-flex items-center bg-white rounded-xl py-3 px-3 pr-6 md:mr-4 mb-4 md:mb-0"
              >
                <GooglePlayIcon className="w-8 h-8" color="#fff" />
                <span className="flex flex-col leading-3 ml-4">
                  <span className="text-[10px]">GET IT ON</span>
                  <span className="text-md font-medium text-lg">
                    Google Play
                  </span>
                </span>
              </a>
              <a
                href="https://apps.apple.com/us/app/icon-logistics/id1581569291"
                target="_blank"
                className="text-white flex md:inline-flex items-center bg-gray-900 rounded-xl py-3 px-3 pr-6"
              >
                <AppleIcon className="w-8 h-8" color="#fff" />
                <span className="flex flex-col leading-3 ml-4">
                  <span className="text-[10px]">Download on the</span>
                  <span className="text-md font-medium text-lg">App Store</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
