import React from "react"
import ContactSection from "../components/ContactSection"
import QuoteForm from "../components/QuoteForm"
import { Container } from "../components/ui"

export default function QuotePage({ data }) {
  return (
    <div className="">
      <Container width="tight" className="!px-0" >
        <QuoteForm />
      </Container>
    </div>
  )
}
