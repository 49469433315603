import React from "react"
import Card from "../elements/Card"
import { Container } from "../ui"
import { BookmarkIcon, CalendarIcon, CurrencyDollarIcon, DeviceMobileIcon, DocumentIcon, GlobeAltIcon, LocationMarkerIcon } from "@heroicons/react/solid"



export default function EServices() {
  const services = [
    { title: "Tracking", link: "https://portal.iconshipping.com/tracking", icon: <LocationMarkerIcon className="w-4 h-4" /> },
    { title: "Schedules", link: "https://portal.iconshipping.com/schedules", icon: <CalendarIcon className="w-4 h-4"/> },
    { title: "Quotation", link: "/request-a-quote", icon: <CurrencyDollarIcon className="w-4 h-4"/> },
    { title: "Booking", link: "https://portal.iconshipping.com/bookings", icon: <BookmarkIcon className="w-4 h-4"/> },
    { title: "Mobile App", link: "/by-innovation-and-digitization", icon: <DeviceMobileIcon className="w-4 h-4"/> },
    { title: "Forms", link:"/download-forms", icon: <DocumentIcon className="w-4 h-4"/> },
  
  ]

  return (
    <Container>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 mt-12 mb-20">
        {services.map((item) => (
          <Card key={item.title} link={item.link} >
            <div className="flex items-center flex-col py-2">
              <div className="text-gray-600 ring-2 ring-primary rounded-full w-7 h-7 inline-flex items-center justify-center">{item.icon}</div>
              <div className="text-black mt-4 font-medium">{item.title}</div>
            </div>
          </Card>
        ))}
      </div>
    </Container>
  )
}
