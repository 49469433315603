import { navigate } from "gatsby";
import React from "react";
import { classNames } from "../../helpers";

export default function Card({children, title, subtitle, content, onClick, className, link, disableHoverEffect}) {
	const onClickAction = () => {
		if (link) {
			navigate(link);
		}
		else {
			onClick();
		}
	}
	return (
		<div 
			className={classNames(
				"py-4 px-4 bg-white shadow-md rounded-md ring-1 ring-gray-100 transition-all",
				!disableHoverEffect && "hover:scale-105",
				link && "cursor-pointer",
				className
			)}
			onClick={onClickAction}
		>
			{!!title && (
				<div className="">{title}</div>
			)}
			{!!subtitle && (
				<div>{subtitle}</div>
			)}
			{!!content && (
				<div>{content}</div>
			)}
			{children}
		</div>
	)
}