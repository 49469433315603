import React, { useState } from "react"

import { ClockIcon, MailIcon, PhoneIcon } from "@heroicons/react/outline"
import { ContactForm } from "@logisoft/elements";

export default function ContactSection() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault()
    let myForm = e.currentTarget
    let formData = new FormData(myForm)
    fetch("https://iconshipping.wpengine.com/forms/contact.php", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
    .then((res) => {
      
      if(res.status === 200) {
        console.log("Form successfully submitted")
        setSuccess(true)
        setError(false)
      }
      else {
        setSuccess(false)
        setError(true)
      }
      
    })
    .catch((error) => {
      setError(true)
      setSuccess(false)
    })
  }
  

  return (
    <div className="">
      <div className="relative bg-white shadow-xl rounded-xl overflow-hidden">
        <h2 className="sr-only">Contact us</h2>

        <div className="grid grid-cols-1 lg:grid-cols-3">
          {/* Contact information */}
          <div className="relative overflow-hidden py-10 px-6 bg-primary sm:px-10 xl:p-12">
            <div
              className="absolute inset-0 pointer-events-none sm:hidden"
              aria-hidden="true"
            >
              <svg
                className="absolute inset-0 w-full h-full"
                width={343}
                height={388}
                viewBox="0 0 343 388"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                  fill="url(#linear1)"
                  fillOpacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear1"
                    x1="254.553"
                    y1="107.554"
                    x2="961.66"
                    y2="814.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
              aria-hidden="true"
            >
              <svg
                className="absolute inset-0 w-full h-full"
                width={359}
                height={339}
                viewBox="0 0 359 339"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                  fill="url(#linear2)"
                  fillOpacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear2"
                    x1="192.553"
                    y1="28.553"
                    x2="899.66"
                    y2="735.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
              aria-hidden="true"
            >
              <svg
                className="absolute inset-0 w-full h-full"
                width={160}
                height={678}
                viewBox="0 0 160 678"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                  fill="url(#linear3)"
                  fillOpacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear3"
                    x1="192.553"
                    y1="325.553"
                    x2="899.66"
                    y2="1032.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <h3 className="text-lg font-medium text-white">
              Icon Logistics LLC
            </h3>
            <p className="mt-6 text-base text-primary-50 max-w-3xl">
              14725 Baltimore Avenue Suite -B Laurel MD 20707
            </p>
            <dl className="mt-8 space-y-6">
              <dt>
                <span className="sr-only">Phone number</span>
              </dt>
              <dd className="flex text-base text-primary-50">
                <PhoneIcon
                  className="flex-shrink-0 w-6 h-6 text-primary-200"
                  aria-hidden="true"
                />
                <span className="ml-3">+1 301-483-8510</span>
              </dd>
              <dt>
                <span className="sr-only">Email</span>
              </dt>
              <dd className="flex text-base text-primary-50">
                <MailIcon
                  className="flex-shrink-0 w-6 h-6 text-primary-200"
                  aria-hidden="true"
                />
                <span className="ml-3">info@iconshipping.com</span>
              </dd>
              <dd className="flex text-base text-primary-50">
                <ClockIcon
                  className="flex-shrink-0 w-6 h-6 text-primary-200"
                  aria-hidden="true"
                />
                <span className="ml-3">Mon - Fri 09:00 am - 06:00 pm</span>
              </dd>
            </dl>
          </div>

          {/* Contact form */}
          <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <h3 className="text-lg font-medium text-gray-900 mb-10">
              Send us a message
            </h3>

            <iframe
              style={{
                width: "100%",
                height: "850px",
                minHeight: "55vh",
                border: "none",
                overflowY: "auto",
              }}
              src="https://portal.iconshipping.com/contact?isFramed=true"
              frameborder="0"
            ></iframe>

            {/* <ContactForm primaryColor="#FE5B18" contained={false} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
