import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/digi.jpg"
import IMG2 from "./../../assets/images/portal.jpeg"
import IMG3 from "./../../assets/images/roro-vessel.jpg"
import EServices from "../../components/homepage/eServices"
import MobileAppCTA from "../../components/homepage/MobileAppCTA"

export default function ByInnovationPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        title="Technology Is Vital to Freight Forwarding"
        className="bg-primary-50 mt-0 pt-8"
        hideBackPattern
      >
        At Icon Logistics Services, we believe that innovation is about looking
        beyond what is to what could be. We believe in technology; in its
        ability to challenge the status quo. In all honesty, we don’t believe in
        doing freight forwarding the traditional way.
        <br />
        <br />
        In this time and age, our role as the leading freight forwarding company
        heavily relies on providing value-added solutions and services to our
        clients. For this reason, we question our own processes before
        redefining the logistics of tomorrow by evaluating trends and
        technologies.
      </CenteredTextBlock>

      <LeftImageText
        title="Digital Solutions for Added-Value"
        //button={{ label: "Explore Our Services", to: "#" }}
        image={IMG1}
      >
        What the COVID-19 pandemic has made clear is one thing; the need for the
        logistics industry to operate in a digital and lean manner. The digital
        solutions provided by Icon Logistics Services for our clients will play
        a key role in driving transparency for automated and optimized shipping
        operations.
      </LeftImageText>
      <EServices />


      <RightImageText
        title="Client Portal: A Secure Digital Gateway"
        image={IMG2}
        //variant="light"
        button={{
          label: "Create your account",
          to: "https://portal.iconshipping.com",
        }}
      >
        How do you communicate with clients? Some would say through emails while
        others would prefer a video conferencing application such as Zoom.
        <br />
        <br />
        Well, there’s another way that we’d like to introduce you to.
        <br />
        <br />
        Icon Logistics Services leads the way with its exceptional client portal
        platform that digitizes all trade-related documents, including Bill of
        Lading,
      </RightImageText>

	  <MobileAppCTA detailed />
 
    </>
  )
}
