import React from "react"
import { Box, Container } from "../components/ui"

export default function DefaultPage({data}) {
  return (
    <>
      <Box paddingY={5}>
        <Container width="narrow">
          {/* <Heading as="h1">{page.title}</Heading> */}
          <div
            dangerouslySetInnerHTML={{
              __html: data.html,
            }}
            className="wpbody"
          />
        </Container>
      </Box>
    </>
  )
}
